import React, { useRef, useEffect } from "react"
import classNames from "classnames"
import { Field, ErrorMessage, useField } from "formik"

/**
 ** Input field with label and error message.
 ** Supported parameters:
 **
 ** fieldProps: {
 **               'name': String,
 **               'placeholder': String,
 **               'label': String,
 **               'type': String,
 **               'onChange': Function,
 **               ...props compatible in Field Component of Formik
 **             }
 **/
const FormInput = (fieldProps) => {
  const fieldRef = useRef(null)

  const handleScrollCallback = () => {
    fieldRef.current.focus()
    fieldRef.current.scrollIntoView({ block: "center" })
  }

  useEffect(() => {
    if (fieldProps.isFollowUpQuestion) handleScrollCallback()
  }, [])

  //* Function that prevents alpha and symbols
  //* if the fieldProps.type is number.
  //* This also prevents the user to input characters more than
  //* fieldProps.max (if defined).
  const handleOnKeyPress = (event) => {
    const isTypeNumber = fieldProps.type === "number"

    if (isTypeNumber) {
      if (
        (event.key !== "." && isNaN(event.key)) ||
        (fieldProps.maxLength &&
          event.target.value.toString().length >= fieldProps.maxLength)
      )
        event.preventDefault()
    }
    if (fieldProps.onKeyPress) fieldProps.onKeyPress(event)
  }

  // We're accessing the useField props below so we can validate forms inline, on touch
  // Source: https://jaredpalmer.com/formik/docs/api/useField#usefieldname-string-fieldattributes-fieldinputprops-fieldmetaprops-fieldhelperprops
  const [, meta] = useField(fieldProps.name)
  return (
    <div className="mb-2">
      <label className={classNames("label has-text-weight-normal")}>
        {!!fieldProps.labelIcon && (
          <span className={`icon has-text-${fieldProps.labelIconColor}`}>
            {fieldProps.labelIcon}
          </span>
        )}
        {fieldProps.label}
        {!fieldProps.isRequired && (
          <span className="is-italic has-text-grey">&nbsp;(Optional)</span>
        )}
        {!!fieldProps.helper && (
          <span
            className={classNames(
              "help has-text-weight-normal has-text-grey",
              fieldProps.helperClassName
            )}
          >
            {fieldProps.helper}
          </span>
        )}
      </label>
      <div
        className={classNames("field mb-0", {
          "has-addons has-addons-right": fieldProps.hasAddons,
        })}
      >
        {fieldProps.hasAddons && fieldProps.addonLeft && (
          <div className="control">{fieldProps.addonLeft}</div>
        )}
        <div className={`control ${fieldProps.hasAddons ? "is-expanded" : ""}`}>
          <Field {...fieldProps}>
            {({ field }) => (
              <input
                {...fieldProps}
                {...field}
                ref={fieldRef}
                className={classNames(
                  "input",
                  {
                    "is-success":
                      meta?.touched && !meta?.error && fieldProps.isRequired,
                    "is-danger": meta?.touched && meta?.error,
                  },
                  fieldProps.className
                )}
                onKeyPress={handleOnKeyPress}
                onChange={(event) => {
                  field.onChange(event)
                  if (fieldProps?.onChange) {
                    fieldProps.onChange(event)
                  }
                }}
              ></input>
            )}
          </Field>
        </div>
        {fieldProps.hasAddons && fieldProps.addonRight && (
          <div className="control">{fieldProps.addonRight}</div>
        )}
      </div>
      <p className="help mt-0 mb-1 is-danger">
        <ErrorMessage name={fieldProps.name} />
      </p>
    </div>
  )
}

export default React.memo(FormInput)
